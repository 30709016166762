<template>
  <div class="balance">
    <div class="balanceTitle">
      我的余额
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <div class="balanceTx">
      <div class="title">我的余额</div>
      <div class="balanceTx-div">
        <div class="left">{{ account }}</div>
        <div class="right">提现</div>
      </div>
    </div>
    <div class="balanceTime"></div>
  </div>
</template>
<script>
import { getUserAcc } from "../api/person.js";
export default {
  setup() {},
  data() {
    return {
      account: "",
    };
  },
  mounted() {
    this.getBalanceData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getBalanceData() {
      getUserAcc().then((res) => {
        this.account = res.data.account;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.balance {
  .balanceTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .balanceTx {
    margin-top: 5px;
    padding: 0 22px;
    background-color: #ffffff;
    height: 120px;
    .title {
      padding-top: 16px;
      font-size: 13px;
      color: #999999;
      margin-bottom: 23px;
    }
    .balanceTx-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        font-size: 22px;
        color: #333333;
      }
      .right {
        width: 78px;
        height: 25px;
        border-radius: 15px;
        border: 1px solid rgba(141, 218, 243, 1);
        font-size: 13px;
        color: #333333;
        line-height: 25px;
        text-align: center;
      }
    }
  }
}
</style>